@import "../../static/scss/variables.scss";
.select .MuiInputBase-root {
  margin-top: 0px;
}

.select .MuiFormLabel-root {
  display: none;
}

.select .MuiFormControl-root {
  margin-top: -8px !important;
}
.select input {
  font-size: 0.75rem !important;
}
.MuiButtonBase-root {
  font-size: 0.8rem !important;
}
.select .MuiSelect-root {
  font-size: 0.8rem !important;
}
.Mui-selected {
  background-color: $primary !important;
  color: $white !important;
}
