@import "../../static/scss/variables.scss";

.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: $primary !important;
}

.ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: $primary !important;
}

.css-1wa3eu0-placeholder {
  font-size: 0.8rem !important;
}
.css-1hwfws3 {
  width: 5rem !important;
}
