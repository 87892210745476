.container_class {
  // position: absolute;
  // margin-top: 65px;
  left: 0px;
  right: 0px;

  .view_activity {
    font-size: 16px;
    color: #2a3f54;

    &:hover {
      color: #000000;
      cursor: pointer;
    }
  }

  .filter_module {
    font-size: 15px;
  }
}

.container_class_topntable {
  background-color: #ffffff;
  box-shadow: 0px 0px 2px 1px #00000029;

  .topntable_heading {
    padding-left: 5rem;
    padding-bottom: 1rem;
    font-size: 22px;
    font-family: 'Poppins';
    font-weight: 500;
  }
}

.display_top_promo_data {
  font-size: 12px;
  color: #5a5a5a;
}

.loading_class {
  height: 250px;
  border-top: none;
  font-size: 22px;
  color: grey;
  //   text-align: center;
}

.show_range {
  height: 5px;
  width: 100%;
  background-color: #f5f5f5;
}

.top_display_box {
  position: relative;
  box-shadow: 0px 0px 2px 1px #00000029;
  background-color: #ffffff;
  width: 250px;
  height: 100px;
}

.material_icon {
  color: #ffffff;
  font-size: 3.5rem !important;
}

.align_material_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #3E44BF;
  // background-color: #2a3f54;
  width: 100px;
}

.help_icon {
  position: absolute;
  top: 0px;
  right: 10px;

  .help_icon_size {
    font-size: 1rem !important;
  }

  &:hover {
    cursor: pointer;
  }
}

.display_top_data {
  width: 150px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter_module {
  font-size: 14px;
}

.show_toggle {
  background-color: #3E44BF;
  color: #ffffff;
  padding: 2px 0px;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
  }
}

.filter_dropdown {
  border-radius: 5px;
  border: 1px solid #cfcfd4;

  &:hover {
    cursor: pointer;
  }
}

.sort_arrow {
  background-color: #3E44BF;
  color: #ffffff;
  border-radius: 5px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
}



.table_icon svg {
  fill: #fff;
}
.table_icon {
  background: #3a3abf !important;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px #8181ec;
  padding: 1.2rem 1.15rem;
  border-radius: 0.3rem;
  position: fixed;
  top: 15.7rem;
  left: 19px;
}

.text_data_color {
  color: rgba(0, 0, 0, 0.87);
}