@import "../../static/scss/variables.scss";
.Table__itemCount {
  /* margin-top: 10px; */
  font-size: 0.7rem;
}

.Table__pagination {
  display: flex;
  justify-content: flex-end;
  padding: 20px 10px;
}

.Table__pageButton {
  font-size: 15px;
  outline: 0 !important;
  border: 0 !important;
  background-color: transparent;
  cursor: pointer;
}

.Table__pageButton:hover {
  color: $primary;
}

.Table__pageButton:disabled {
  cursor: not-allowed;
  color: gray;
}

.Table__pageButton--active {
  background: linear-gradient(60deg, $primary, $primary);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px $primary;
  color: $white !important;
  font-size: 0.7rem;
  padding: 5px 10px;
}
