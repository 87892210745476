@import "../../../static/scss/variables.scss";

.reportWrapper .select__single-value {
  color: $grey !important;
  font-weight: 400 !important;
}
.text-grey {
  color: $grey;
  font-size: 14px;
}

.reportWrapper {
  position: absolute;
  left: 255px;
  top: 130px;
  right: 35px;
  padding-bottom: 20px;
}

.reportWrapper .title {
  font-size: 26px;
  font-weight: 500;
}

.reportWrapper .rangePickr {
  width: 300px;
}

.reportWrapper .spaceBetween {
  display: flex;
  justify-content: space-between;
}

.reportWrapper .label {
  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px dotted $light-light-grey;
}

.reportWrapper .link {
  font-size: 13px;
  color: $primary;
}

.reportWrapper .numericValue {
  margin-top: 10px;
  font-size: 25px;
  font-weight: 500;
}

.reportWrapper .dash {
  font-weight: 500;
}

.reportWrapper .visitor {
  font-size: 13px;
}

.reportWrapper .graphTitle {
  font-weight: 500;
  font-size: 14px;
}

.reportWrapper .card {
  box-shadow: 1px 1px 3px $light-light-grey;
}

.reportWrapper .head {
  position: relative;
  bottom: 40px;
}

// .reportWrapper tspan,
// .reportWrapper .highcharts-point,
// .reportWrapper .highcharts-graph,
.reportWrapper .highcharts-legend,
.reportWrapper .highcharts-credits {
  display: none !important;
}

.selectOption {
  margin-left: 10px;
  height: 20px;
  width: 110px;
}

.reportWrapper .overviewBody {
  position: relative;
  bottom: 20px;
}

.reportWrapper .selectBody {
  width: 110px;
}

.fadeIn {
  -webkit-animation: fadeIn 0.5s; /* Safari 4+ */
  -moz-animation: fadeIn 0.5s; /* Fx 5+ */
  -o-animation: fadeIn 0.5s; /* Opera 12+ */
  animation: fadeIn 0.5s; /* IE 10+, Fx 29+ */
}
.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 30px;
}

.highcharts-tooltip-box {
  fill: black;
  fill-opacity: 0.6;
  stroke-width: 0;
}

.highcharts-tooltip text {
  fill: white !important;
  text-shadow: 0 0 3px black;
}

.reportWrapper .ant-calendar-picker-input {
  height: 38px !important;
}

.reportWrapper .react-table {
  position: relative;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.reportWrapper .table-icon {
  display: none;
}

.reportWrapper .time-range-div {
  display: none !important;
}

.reportWrapper .table-lable {
  display: none !important;
}

.reportWrapper .tableWrapper {
  position: relative;
}

.reportWrapper .table-head {
  position: relative;
  top: 19px;
  z-index: 2;
  background: white;
  border-radius: 3px;
  padding: 20px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
}

.reportWrapper .filter-button {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  cursor: pointer;
}

.reportWrapper .manage-filters-window {
  position: fixed;
  top: 60px;
  left: 220px;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.14);
  display: flex;
  z-index: 10;
}

.reportWrapper .manage-filters-box {
  width: 620px;
  margin: auto;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.4);
  position: relative;
}

.reportWrapper .delete {
  padding: 6px 11px;
  border: 1px solid #bbb;
  border-radius: 3px;
  color: $grey;
}

.reportWrapper .add-filter {
  padding: 6px 11px;
  border: 1px solid #bbb;
  border-radius: 3px;
  font-size: 14px;
}

.reportWrapper .apply-filter {
  padding: 6px 11px;
  border-radius: 3px;
  font-size: 14px;
  color: $white;
  background-color: $primary;
}

.reportWrapper .search .select__value-container {
  padding-left: 30px;
  overflow: visible;
}

.reportWrapper .search {
  position: relative;
}

.reportWrapper .search .fa-search {
  position: absolute;
  z-index: 1;
  padding: 11px;
  color: $light-grey;
}

.reportWrapper .manage-head {
  font-size: 18px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: white;
}

.reportWrapper .filter-options {
  margin-top: 88px !important;
}

.css-4ljt47-MenuList {
  max-height: 190px !important;
}

.overflow {
  overflow-y: scroll;
  max-height: 480px;
}
