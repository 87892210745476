$grey: #555;
$light-grey: #999;
$light-light-grey: #ddd;
$primary: #3a3abf;  //#3a3abf - shoppd_theme , ffcd0096 -roadyo Theme
$primary-vlight: rgba(58, 58, 191, 0.1);
$primary-light: rgba(58, 58, 191, 0.7);
$body-bg: #e6e6e6;
$white: #fff;
$red: red;
$black: #000;
$blue-shadow: #8181ec;
$primary-gradiant: linear-gradient(60deg, $primary, $primary);
$primary-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px $blue-shadow;
$font-family: "Roboto", sans-serif;
$theme-colors: (
  primary: $primary
);
// $svg-color: #000
$icon-color: #fff

