.containerDiv {
  width: 100%;
  padding: 0 25px 50px 15px;

  position: absolute;
  left: 10px;
}


.container_class {
  position: absolute;
  // margin-top: 65px;
  left: 0px;
  right: 0px;

  .filter_module {
    font-size: 15px;
  }
}

.inner_container_class {
  background-color: #ffffff;
  font-size: 15px;
  box-shadow: 0px 0px 2px 1px #00000029;

  margin-top: 11rem;
  width: 100%;
  min-height: calc(100vh - 20rem);
}




.inner_container_class > div:nth-child(1) {
  font-weight: 500;
}

#loading_class {
  border-top: none;
  font-size: 1.5rem;
  color: grey;
  height: 512px;
}

.table_heading {
  font-size: 12px;
  font-weight: 520;
  font-family: 'Poppins';
}

.table_heading,
.table_Data_class {
  border: 1px solid #dee2e6;
}

.table_Data_class {
  font-size: 12px;
}

.link_class {
  color: #3a3abf;
  &:hover {
    color: #3a3abf !important;
    cursor: pointer !important;
    text-decoration: underline !important;
  }
}

.view_activity {
  padding-bottom: 4px;
  font-size: 16px;
  color: #2a3f54;

  &:hover {
    color: #000000;
    cursor: pointer;
  }
}

.export_modal_poper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.export_modal {
  background-color: #ffffff;
  max-width: 500px !important;
  border-radius: 5px;
}




.reportModal_Btn {
  height: 34px;
  display: inline-block;
  padding: 0.4rem 1rem;
  font-size: 16px !important;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  border: none;
  border-radius: 15px;
}

.reportModal_Btn:hover {background-color: #bfbfbf !important}

.reportModal_Btn:active {
  transform: translateY(2px);
}

.main_heading {
  font-size: 22px;
  font-family: 'Poppins';
  padding-bottom: 1rem;
  padding-left: 5rem;

}

.table_icon svg {
  fill: #fff;
}
.table_icon {
  background: #3a3abf !important;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px #8181ec;
  padding: 1.2rem 1.15rem;
  border-radius: 0.3rem;
  position: fixed;
  top: 10.2rem;
  left: 38px;
}


.log_table_icon svg {
  fill: #fff;
}

.log_table_icon {

  background: #3a3abf !important;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px #8181ec;
  padding: 1.2rem 1.15rem;
  border-radius: 0.3rem;
  position: fixed;
  top: 6.3rem;
  left: 33px;
}