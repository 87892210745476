.container_class {
    background-color: #ffffff;
    box-shadow: 0px 0px 2px 1px #00000029;
    min-width: 500px;

    .heading {
        font-size: 16px;
        font-weight: 500;
    }
    
    
    .view_activity {
        font-size: 16px;
        color: #3a3abf;

        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .table_heading {
        font-size: 14px;
        color: #000000;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 500;
    }

    .sort_data {
        font-size: 12px;
    }

    .table_Data_class {
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        color: rgba(0, 0, 0, 0.87);
        font-size: 11px;
        border-bottom: 1px solid #d2d2d2;

    }
    
    .selected_sort {
        border-radius: 3px;
        background-color: #f5f5f5;

        &:hover {
            cursor: pointer;
        }
    }
}

.sort_menu_top_table {
    min-height: 30px;
}

.display_top_promo_data {
    font-size: 12px;
    color: #5a5a5a;
}

.loading_class {
    border-top: none;
    font-size: 18px;
    color: grey;
    text-align: center;
}
