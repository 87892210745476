.globalFontSize {
    font-size: 16px !important;
}

.fontweightone {
    font-weight: 400 !important;
}

.fontweightone {
    font-weight: 500 !important;
}


.globalFontFamily {
    font-family: 'Poppins';
}