.container_class {
    background-color: #ffffff;
    box-shadow: 0px 0px 2px 1px #00000029;
    max-width: 500px;
}

.heading {
    font-size: 16px;
    font-weight: 500;
}

.view_activity {
    font-size: 16px;
    color: #3a3abf;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

#loading_class {
    border-top: none;
    font-size: 18px;
    color: grey;
}

.table_heading {
    font-size: 14px;
    color: #000000;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
}

// .table_heading,
.table_Data_class {
    // font-size: 12px;
    border-bottom: 1px solid #d2d2d2;
}

.table_Data_class {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    color: rgba(0, 0, 0, 0.87);
    font-size: 11px;
}

.filter_module_group {
    height: 24px;
    border-radius: 4px;

    &:hover {
        cursor: pointer;
    }

    &:focus {
        outline: none;
    }
}

.filter_module {
    font-size: 12px;
}