@import "../../static/scss/variables.scss";

// .react-select__value-container {
//   border: 10px solid red !important;

// }
.select__single-value {
  font-weight: 500;
  font-size: 0.8rem;
  color: $grey !important;
}

.select__control--menu-is-open {
  border: 1.5px solid $primary !important;
}

.select__control {
  width: 100%;
  height: 32px;
  min-height: 32px !important;
  &:hover {
    border: 1px solid black !important;
  }
  // border: 1px solid #bbb;
}

.select__menu > div > div {
  &:hover {
    background-color: $primary-vlight;
  }
}

.select__option--is-selected {
  background-color: $primary !important;
}

.cursor-pointer {
  cursor: pointer;
}

.no-filter-text {
  font-size: 14px;
}

.select__indicators{
  height: 32px;
}

.select__value-container {
  height: 32px;
}
