@import "../../static/scss/variables.scss";
.login-form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  background-color: $white;
  font-size: 0.8rem !important;
}

.forgotPassword {
  color: $primary;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.forgotPassWrapper {
  height: 495px;
  width: 368px;
  position: relative;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

.forgotPassWrapper .backArrow {
  height: 20px;
  &:hover {
    cursor: pointer;
  }
}

.forgotPassWrapper .sub {
  font-size: 20px;
  font-weight: bold;
}

.forgotPassWrapper .description {
  color: $grey;
  font-weight: 400;
}

.forgotPassWrapper .email {
  color: $light-grey;
}

.forgotPassWrapper .emailId {
  color: $primary;
  font-weight: 500;
}

.forgotPassWrapper .emailStatus {
  font-size: 0.7rem;
  margin-top: 4px;
  float: right;
  color: $red;
}
.forgotPassWrapper .resetLink {
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: 15px;
}
.login-form .title {
  font-size: 25px;
  background-color: #3a3abf;
  height: 40;
  border-radius: 5px;
  padding: 2px 20px;
  color: white;
  position: absolute;
  top: -21px;
  right: 82px;
}

.login-form .card {
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  padding: 0px 1.5rem;
  min-width: 23rem;
}
.app-logo {
  margin-top: 30px;
  height: 84px;
  width: 276px;
  padding-right: 44px;
}
.login-form-inner {
  margin-top: -100px;
}
.login-form {
  .btn {
    border: 0px;
    padding: 0.5rem 2rem;
    font-size: 0.8rem;
    width: 100%;
    background: $primary-gradiant !important;
    box-shadow: $primary-shadow;
  }
  .form-control {
    height: 35px !important;
    font-size: 0.8rem;
  }
  .form-control:focus {
    outline: none;
    border-radius: none;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
  }
  .form-label {
    margin-bottom: 0.5rem;
  }

  .logo {
    // margin-bottom: 1.8rem;
    text-align: center;
  }

  .show {
    visibility: visible;
  }
  .hide {
    visibility: hidden;
  }
  .error {
    color: $red;
    font-size: 0.7rem;
    margin-top: 4px;
    text-align: end;
  }
}
.btn-primary {
  background-color: $primary;
}

.message {
  margin: auto;
  filter: hue-rotate(226deg);
}
