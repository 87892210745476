.container_class {
  margin-top: 20rem im !important;
  background-color: #ffffff;
  box-shadow: 0px 0px 2px 1px #00000029;

  .heading {
    font-size: 16px;
    font-weight: 500;
  }

  .loading_class {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 380px;
    font-size: 1.5rem;
    color: grey;
  }
}

.main_tag_data {
  width: 48%;
  height: 50px;
  font-size: 16px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
}

.coupon_summary_heading {
  position: absolute;
  top: -34px;
  color: #6d6d6d;
  font-size: 16px;
  left: -8px;
}

.filter_module {
  font-size: 15px;
}
